import { useState, useEffect, useRef } from "react";
import { useCSVReader, formatFileSize } from "react-papaparse";
import axios from "axios";
import Headings from "../../components/universal/Headings";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";

const HRTimeOffAndAttendance = ({
  bgColor,
  hoverColor,
  disabledColor,
  fillColor,
  textColor,
  accentColor,
  lightColor,
  focusBorder,
  borderColor,
  progressColor,
}) => {
  const [isView, setIsView] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { CSVReader } = useCSVReader();
  const [col, setCol] = useState([]);
  const [val, setVal] = useState([]);

  const [notif, setNotif] = useState("");

  const uploadBtnRef = useRef(null);

  const [modalView, setModalView] = useState([]);

  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [rowIndex, setRowIndex] = useState(0);

  const [selectedEmployeeNumber, setSelectedEmployeeNumber] = useState("")
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("")

  const [searchTerm, setSearchTerm] = useState("")

  

  //Add Date
  const [newDate, setNewDate] = useState({
    employee_id: selectedEmployeeNumber,
    time_in: "",
    time_out: "",
    date: new Date(),
  });

  const handleSubmit = () => {
    uploadBtnRef.current.close()
    toast.loading("Loading...")

    axios
      .post(BASE_URL + "/mtaa-insertAttendanceData", val)
      .then((res) => {
        if (res.data === "success") {
          setNotif("success");
          notifySuccess();
        } else if (res.data === "error") {
          setNotif("Error Uploading Data");
          notifyFailed();
        }
      })
      .catch((err) => {
        setNotif("error");
        notifyFailed();
      });
  };

  const handleAddNewDate = (event) => {
    document.getElementById("add_new_date_modal").close()

    axios
      .post(BASE_URL + "/mtaa-addNewDate", newDate)
      .then((response) => {

        //Clear variable and form
        setNewDate([])
        document.getElementById("newDateForm").reset()

        //Frontend Add

        // setDisabledDates([{...disabledDates,
        //   date: response.data.date,
        // }])

        setSelectedAttendance([{
          attendance_id: response.data.insertId,
          employee_id: response.data.employee_id,
          surname: null,
          department: null,
          date: response.data.date,
          time_in: response.data.time_in, 
          time_out: response.data.time_out,
          total_break: null,
          hours_logged: null,
          hours_worked: response.data.hours_worked,
          status: response.data.status,
          undertime: response.data.undertime,
          date_uploaded: new Date()
          },
          ...selectedAttendance])
      })
      .catch((e) => {
        setNotif("error");
        notifyFailed();

        setNewDate([])
        document.getElementById("newDateForm").reset()
      });
  };

  const [editData, setEditData] = useState([]);

  const renderEditData = (id, timein, timeout) => {
    setEditData({
      ...editData,
      attendance_id: id,
      time_in: timein,
      time_out: timeout,
    });
  };

  //View Employee Section

  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedAttendance, setSelectedAttendance] = useState([]);
  const [selectedLeaves, setSelectedLeaves] = useState([]);

  const [isViewLoading, setIsViewLoading] = useState(true)

	const [totalRows2, setTotalRows2] = useState(0);
	const [perPage2, setPerPage2] = useState(10);

  const [currentPage, setCurrentPage] = useState(1)

  const fetchSelectedAttendance = async (page, id) => {
    setIsView(true);
    setIsViewLoading(true)

    setSelectedEmployeeId(id)

    setNewDate({...newDate, employee_id: id})

    setSelectedStatus(
      attendanceList.filter((row) => {
      return Object.values(row).some((value) =>
        JSON.stringify(value).includes(id)
        );
      })
    )

		const response = await axios.get(BASE_URL + `/mtaa-getPaginatedAttendanceOfOne?page=${page}&employeeNumber=${id}&limit=${perPage2}&delay=1`);

    console.log("SD: ", response.data.data2)

		setSelectedAttendance(response.data.data2);
		setTotalRows2(response.data.pagination.total);
		setIsViewLoading(false);
	};

  const handlePageChange2 = (page, id) => {
    setCurrentPage(currentPage + 1)
		fetchSelectedAttendance(currentPage, selectedEmployeeId);
	};

	const handlePerRowsChange2 = async (newPerPage, page) => {
		setLoading(true);

		const response = await axios.get(BASE_URL + `/mtaa-getPaginatedAttendanceOfOne?page=${page}&employeeNumber=${selectedEmployeeId}&limit=${newPerPage}&delay=1`);

		setSelectedAttendance(response.data.data2);
		setPerPage2(newPerPage);
		setLoading(false);
	};

  const [isSearch, setIsSearch] = useState(false)
  const [searchData, setSearchData] = useState([])

  const fetchSearch = async page => {
    setIsSearch(true);
		setLoading(true);

		const response = await axios.get(BASE_URL + `/mtaa-searchAttendanceList?searchTerm=${searchTerm}`);

    console.log("Search Data: ", response.data)

		setAttendanceList(response.data);
    setSearchData(response.data);
		setLoading(false);
	};

  const handleSearch = () => {
    fetchSearch()
  }

  const isExisting = (date) => {
    const formattedDate = date.toISOString().split("T")[0];
    return (
      !JSON.stringify(selectedAttendance).includes(formattedDate)
    );
  };

  const notifySuccess = () =>
    toast.success("Successfully uploaded the applicants data.", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const notifyFailed = () =>
    toast.error("Something went wrong.", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const handleSaveAttendance = async () => {
    await axios
      .post(BASE_URL + "/mtaa-changeAttendanceOfOne", editData)
      .then((response) => {
        setSelectedAttendance((prev) =>
          prev.map((o, i) =>
            i === rowIndex
              ? {
                  ...o,
                  time_in: editData.time_in,
                  time_out: editData.time_out,
                  hours_worked: response.data[0].hours_worked,
                  status: response.data[0].status,
                  undertime: response.data[0].undertime,
                }
              : o
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      name: "Date",
      selector: (row, i) => moment(row.date).format("ddd, MMM DD YYYY"),
      sortable: true,
    },
    {
      name: "Check In",
      selector: (row, i) =>
        !selectedLeaves.includes(moment(row.date).format("YYYY-MM-DD")) ? (
          <input
            type="time"
            className={`bg-white w-[95px] transition-all ease-in-out outline-none border border-[#e4e4e4] disabled:border-transparent px-1 rounded-[3px]`}
            value={
              selectedIndex === row.attendance_id
                ? editData.time_in
                : row.time_in
            }
            disabled={selectedIndex === row.attendance_id ? false : true}
            onChange={(e) =>
              setEditData({
                ...editData,
                time_in: e.target.value,
              })
            }
          />
        ) : (
          <p>"Leaves"</p>
        ),
    },

    {
      name: "Check Out",
      selector: (row, i) => (
        <input
          type="time"
          className={`bg-white w-[95px] transition-all ease-in-out outline-none border border-[#e4e4e4] disabled:border-transparent px-1 rounded-[3px]`}
          value={
            selectedIndex === row.attendance_id
              ? editData.time_out
              : row.time_out
          }
          disabled={selectedIndex === row.attendance_id ? false : true}
          onChange={(e) =>
            setEditData({
              ...editData,
              time_out: e.target.value,
            })
          }
        />
      ),
    },

    {
      name: "Hours Worked",
      selector: (row, i) =>
        row.hours_worked === "null" || row.hours_worked === ""
          ? null
          : row.hours_worked,
    },

    {
      name: "Start Status",
      selector: (row, i) => row.status,
    },

    {
      name: "Completion Status",
      selector: (row, i) => row.undertime,
    },

    {
      name: "Action",
      selector: (row, i) =>
        selectedIndex === row.attendance_id ? (
          <button
            className={`outline-none border px-3 py-1 ${borderColor} ${textColor} rounded-[5px]`}
            onClick={() => {
              handleSaveAttendance();
              setSelectedIndex(0);
            }}
          >
            Save
          </button>
        ) : (
          <button
            className={`outline-none border px-3 py-1 ${borderColor} ${textColor} rounded-[5px]`}
            onClick={() => {
              setSelectedIndex(row.attendance_id);
              setRowIndex(i);
              console.log("Index: ", i);
              setEditData({
                ...editData,
                attendance_id: row.attendance_id,
                emp_num: row.employee_id,
                time_in: row.time_in,
                time_out: row.time_out,
              });
            }}
          >
            Edit
          </button>
        ),
      width: "100px",
    },
  ];


  const [attendanceList, setAttendanceList] = useState([]);
  const [defaultData, setDefaultData] = useState([])

	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);

  const fetchAttendance = async page => {
		setLoading(true);

		const response = await axios.get(BASE_URL + `/mtaa-getPaginatedAttendanceList?page=${page}&limit=${perPage}&delay=1`);

    console.log(response.data.data2)

		setAttendanceList(response.data.data2);
    setDefaultData(response.data.data2);
		setTotalRows(response.data.pagination.total);
		setLoading(false);
	};

  const handlePageChange = page => {
		fetchAttendance(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		setLoading(true);

		const response = await axios.get(BASE_URL + `/mtaa-getPaginatedAttendanceList?page=${page}&limit=${newPerPage}&delay=1`);

		setAttendanceList(response.data.data2);
		setPerPage(newPerPage);
		setLoading(false);
	};

	useEffect(() => {
		fetchAttendance(1); // fetch page 1 of users
	}, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const attendance_list_res = await axios.get(
  //         BASE_URL + "/mtaa-getAttendanceList"
  //       );
  //       setAttendanceList(attendance_list_res.data);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   fetchData();
  // }, [attendanceList]);

  const [filterText, setFilterText] = useState('')

  const handleFilter = (e) => {
    const value = e.target.value || '';
    setFilterText(value)
  }
  
  // const filteredData = attendanceList.filter((row) => {
  //   return Object.values(row).some((value) =>
  //   value.toString().toLowerCase().includes(filterText.toLowerCase())
  //   );
  // })

  const allAttendanceColumns = [
    {
      name: "Employee Number",
      selector: (row, i) => row.employee_id,
      sortable: true,
    },
    {
      name: "Employee Name",
      selector: (row, i) => row.f_name + " " + row.s_name,
      sortable: true,
    },

    {
      name: "Early Start",
      selector: (row, i) => row.early_start,
      sortable: true,
    },

    {
      name: "Late Start",
      selector: (row, i) => row.late_start,
      sortable: true,
    },

    {
      name: "Overtime",
      selector: (row, i) => row.overtime,
      sortable: true,
    },

    {
      name: "Undertime",
      selector: (row, i) => row.undertime,
      sortable: true,
    },
    {
      name: "Completed",
      selector: (row, i) => row.completed,
      sortable: true,
    },
    {
      name: "Data Incomplete",
      selector: (row, i) => row.data_incomplete,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row, i) => (
               <button
                onClick={() => {
                  fetchSelectedAttendance(1, row.employee_id);
                }}
                  className={`outline-none border px-3 py-1 ${borderColor} ${textColor} rounded-[5px]`}
                >
                  View
                </button>
      ),
    },
  ];

  return (
    <>
      <div className="p-5 max-w-[1300px] m-auto grid">
        <Headings text={"Time Off & Attendance"} />

        {!isView ? (
          <div className="mt-10 w-full bg-white h-[350px] border border-[#e4e4e4] rounded-[15px] flex flex-col justify-center items-center">
            <svg
              viewBox="0 0 146 109"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="h-[150px]"
            >
              <g clip-path="url(#clip0_3843_23070)">
                <path
                  d="M106.609 103.567H103.704L102.321 92.374H106.609V103.567Z"
                  fill="#9E616A"
                />
                <path
                  d="M108.142 108.594H106.059L105.687 106.629L104.734 108.594H99.2089C98.9441 108.594 98.6861 108.51 98.4728 108.353C98.2595 108.196 98.1019 107.975 98.0231 107.723C97.9443 107.47 97.9483 107.199 98.0347 106.949C98.1211 106.699 98.2852 106.483 98.5031 106.332L102.916 103.288V101.302L107.557 101.579L108.142 108.594Z"
                  fill="#36454F"
                />
                <path
                  d="M123.867 99.9044L121.536 101.636L113.74 93.4785L117.181 90.9229L123.867 99.9044Z"
                  fill="#9E616A"
                />
                <path
                  d="M128.101 103.025L126.429 104.267L124.957 102.912L125.366 105.056L120.932 108.349C120.72 108.507 120.462 108.593 120.198 108.595C119.933 108.596 119.674 108.513 119.46 108.357C119.246 108.201 119.087 107.981 119.007 107.729C118.927 107.477 118.93 107.206 119.015 106.955L120.737 101.883L119.551 100.289L123.44 97.7444L128.101 103.025Z"
                  fill="#36454F"
                />
                <path
                  d="M109.991 45.8188C109.991 45.8188 115.503 50.5755 112.96 64.1356L109.855 77.4222L119.98 94.2013L116.752 98.1245L103.589 79.9631L98.8189 68.7867L97.4805 48.8948L109.991 45.8188Z"
                  fill="#36454F"
                />
                <path
                  d="M101.448 71.9717L101.116 77.1003L102.131 98.955L106.905 98.1245L107.817 82.5642L101.448 71.9717Z"
                  fill="#36454F"
                />
                <path
                  d="M40.1839 106.198L42.7819 106.198L44.0176 96.1887L40.1836 96.1889L40.1839 106.198Z"
                  fill="#FFB6B6"
                />
                <path
                  d="M39.5215 105.351L40.2527 105.351L43.1075 104.191L44.6376 105.35H44.6378C45.5025 105.35 46.3318 105.694 46.9432 106.304C47.5546 106.915 47.8981 107.743 47.8982 108.607V108.713L39.5216 108.713L39.5215 105.351Z"
                  fill="#36454F"
                />
                <path
                  d="M50.251 104.563L52.7849 105.134L56.1977 95.6429L52.4578 94.7993L50.251 104.563Z"
                  fill="#FFB6B6"
                />
                <path
                  d="M49.7922 103.59L50.5054 103.751L53.5459 103.248L54.7827 104.716L54.7829 104.716C55.6263 104.906 56.3595 105.424 56.8213 106.154C57.283 106.884 57.4354 107.768 57.245 108.61L57.2217 108.713L49.0508 106.871L49.7922 103.59Z"
                  fill="#36454F"
                />
                <path
                  d="M36.8936 45.0068C35.6756 48.6763 35.8044 52.6593 37.1864 56.9342L37.7771 81.003C37.7771 81.003 36.8969 96.7869 39.7372 102.341H43.8564L45.8959 79.5616L46.152 64.2719L54.9831 77.1712L49.3583 101.969L54.4281 102.421L63.5556 75.5104L52.294 49.4021L36.8936 45.0068Z"
                  fill="#36454F"
                />
                <path
                  d="M84.3975 34.5549C87.5482 31.7188 92.2346 30.2928 96.1656 31.8831C92.1332 34.1194 89.0227 37.7083 87.3843 42.0149C86.7585 43.6781 86.2358 45.5931 84.6966 46.4835C83.7388 47.0375 82.5472 47.0646 81.4734 46.7972C80.3994 46.5297 79.4151 45.9935 78.4442 45.4627L78.168 45.4564C79.4081 41.405 81.2468 37.3909 84.3975 34.5549Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M96.1434 31.9691C92.6619 32.4186 89.4106 33.9523 86.8513 36.3526C86.2912 36.8596 85.8146 37.4516 85.439 38.1068C85.0938 38.7569 84.9292 39.4874 84.9622 40.2226C84.9792 40.9086 85.0762 41.6084 84.9259 42.2866C84.8439 42.6327 84.6895 42.9575 84.4729 43.2397C84.2564 43.522 83.9826 43.7554 83.6694 43.9245C82.9001 44.3662 82.0178 44.5055 81.1502 44.6117C80.1869 44.7296 79.1845 44.8369 78.3506 45.3766C78.2496 45.442 78.1539 45.2816 78.2548 45.2163C79.7055 44.2773 81.5413 44.6189 83.1012 43.9923C83.8291 43.6998 84.4732 43.1865 84.7012 42.4104C84.9005 41.7318 84.8012 41.0101 84.7779 40.3156C84.7352 39.5989 84.8688 38.8827 85.167 38.2295C85.508 37.5573 85.961 36.9479 86.5066 36.4275C87.7163 35.2364 89.106 34.2427 90.6247 33.4828C92.3532 32.6059 94.2191 32.0308 96.1417 31.7824C96.2607 31.7671 96.2617 31.9539 96.1434 31.9691Z"
                  fill="white"
                />
                <path
                  d="M87.63 35.5314C87.2851 35.0478 87.1235 34.4575 87.1741 33.8659C87.2247 33.2743 87.4842 32.72 87.9062 32.3019C87.9919 32.2176 88.1271 32.3466 88.0413 32.431C87.6476 32.8196 87.406 33.336 87.36 33.887C87.314 34.438 87.4667 34.9872 87.7905 35.4356C87.8607 35.5332 87.6998 35.6283 87.63 35.5314Z"
                  fill="white"
                />
                <path
                  d="M84.9078 39.8759C86.1042 40.0877 87.3366 39.8412 88.3592 39.1856C88.4604 39.1206 88.5561 39.281 88.455 39.346C87.3896 40.0262 86.1066 40.2801 84.8621 40.057C84.7435 40.0356 84.7899 39.8547 84.9078 39.8759Z"
                  fill="white"
                />
                <path
                  d="M92.3689 32.8529C92.4633 33.0678 92.6097 33.2559 92.7948 33.4003C92.98 33.5447 93.1982 33.641 93.4298 33.6803C93.5486 33.7002 93.5021 33.8811 93.384 33.8614C93.1284 33.8167 92.8875 33.7101 92.6826 33.551C92.4777 33.3919 92.3149 33.1851 92.2084 32.9487C92.1965 32.9272 92.1932 32.902 92.1992 32.8783C92.2052 32.8545 92.2201 32.8339 92.2407 32.8206C92.2621 32.8081 92.2875 32.8044 92.3115 32.8105C92.3355 32.8165 92.3561 32.8318 92.3689 32.8529Z"
                  fill="white"
                />
                <path
                  d="M100.494 45.5599C100.42 45.5496 100.345 45.5393 100.269 45.5302C99.2666 45.3972 98.2545 45.3459 97.2432 45.3766C97.165 45.3778 97.0857 45.3801 97.0077 45.3835C94.5721 45.4835 92.1763 46.0344 89.9421 47.008C89.0534 47.3963 88.1956 47.8515 87.376 48.3698C86.2442 49.0856 85.0741 49.9517 83.8089 50.2437C83.6772 50.2756 83.5437 50.2993 83.4091 50.3148L78.1816 45.7935C78.1749 45.7773 78.1671 45.7622 78.1603 45.7459L77.9434 45.5749C77.9838 45.5452 78.0263 45.5153 78.0667 45.4857C78.09 45.4683 78.1146 45.452 78.1379 45.4347C78.1539 45.4235 78.1699 45.4123 78.1836 45.4013C78.1889 45.3975 78.1943 45.3938 78.1985 45.3913C78.2123 45.3802 78.2262 45.3714 78.2389 45.3616C78.4763 45.1937 78.7152 45.0272 78.9557 44.8621C78.9567 44.8609 78.9567 44.8609 78.9589 44.8607C80.7795 43.585 82.7451 42.5296 84.8146 41.7165C84.8767 41.6931 84.9398 41.6684 85.0043 41.6471C85.9363 41.3009 86.895 41.0314 87.871 40.8413C88.4057 40.7389 88.9453 40.6636 89.4876 40.6157C90.8892 40.4947 92.3012 40.5946 93.6717 40.9118C96.4037 41.5446 98.9027 43.0432 100.383 45.3802C100.421 45.44 100.457 45.4989 100.494 45.5599Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M100.425 45.6159C97.3742 43.8809 93.8538 43.1502 90.3636 43.5276C89.6108 43.5955 88.8735 43.7815 88.1787 44.0788C87.5112 44.3903 86.9394 44.8745 86.5227 45.4814C86.1228 46.0394 85.7784 46.6564 85.2496 47.1076C84.9756 47.3346 84.6565 47.5011 84.3135 47.5962C83.9705 47.6913 83.6112 47.713 83.2592 47.6597C82.3787 47.5497 81.5903 47.1303 80.8335 46.6933C79.9933 46.2081 79.1283 45.6909 78.1372 45.6204C78.0171 45.6118 78.0374 45.4262 78.1573 45.4347C79.8816 45.5575 81.1415 46.9343 82.7647 47.3721C83.5221 47.5764 84.3458 47.5539 84.9956 47.0713C85.5638 46.6493 85.9195 46.0133 86.3195 45.4449C86.7174 44.8469 87.2557 44.3554 87.8875 44.0132C88.5649 43.6815 89.2939 43.4675 90.0432 43.3801C91.727 43.1566 93.4355 43.1989 95.1062 43.5055C97.0148 43.8448 98.8513 44.5078 100.536 45.4658C100.64 45.5251 100.529 45.6749 100.425 45.6159Z"
                  fill="white"
                />
                <path
                  d="M91.4805 43.3399C91.4966 42.7464 91.7234 42.1779 92.1204 41.736C92.5173 41.2941 93.0586 41.0075 93.6476 40.9275C93.7668 40.9117 93.797 41.096 93.6776 41.1118C93.129 41.1853 92.6248 41.4523 92.256 41.8646C91.8872 42.2769 91.6781 42.8072 91.6663 43.36C91.6637 43.4801 91.4778 43.4593 91.4805 43.3399Z"
                  fill="white"
                />
                <path
                  d="M86.6883 45.1718C87.516 46.0604 88.6485 46.6048 89.8601 46.6963C89.9801 46.7053 89.9599 46.8909 89.84 46.882C88.5793 46.7843 87.4019 46.2155 86.5427 45.2889C86.4608 45.2005 86.6069 45.084 86.6883 45.1718Z"
                  fill="white"
                />
                <path
                  d="M96.8777 44.0514C96.8236 44.2797 96.827 44.5179 96.8878 44.7446C96.9486 44.9713 97.0649 45.1793 97.226 45.35C97.309 45.4373 97.1628 45.5538 97.0804 45.4671C96.9032 45.2776 96.7751 45.0477 96.7074 44.7974C96.6397 44.5472 96.6343 44.2841 96.6919 44.0313C96.6953 44.007 96.7078 43.9849 96.727 43.9696C96.7461 43.9542 96.7704 43.9467 96.7948 43.9485C96.8194 43.9513 96.8419 43.9637 96.8574 43.9829C96.873 44.0022 96.8802 44.0268 96.8777 44.0514Z"
                  fill="white"
                />
                <path
                  d="M8.67725 106.016C8.43186 106.936 7.78707 107.702 7.00858 108.26C6.88166 108.351 6.75135 108.435 6.61934 108.515C6.57873 108.539 6.53811 108.564 6.4958 108.586C6.45011 108.613 6.40441 108.638 6.35872 108.662H2.69816C2.63215 108.528 2.56785 108.393 2.50354 108.26C0.934724 104.967 -0.166999 101.413 0.0208525 97.7939C0.09538 96.39 0.390641 95.0066 0.8958 93.6944C1.90106 91.0793 3.73557 88.8157 6.25887 87.6763C6.32149 87.6476 6.38749 87.6188 6.4518 87.5918C6.43149 87.6645 6.41118 87.7355 6.39088 87.8082C6.12017 88.7823 5.92887 89.7768 5.81886 90.7817C5.8087 90.8595 5.80024 90.9373 5.79347 91.0167C5.55305 93.4405 5.76654 95.8877 6.42303 98.2334C6.42472 98.2385 6.42641 98.2452 6.42811 98.2503C6.57196 98.7693 6.73894 99.2804 6.92904 99.7836C7.07797 100.181 7.23875 100.573 7.41475 100.958C8.14923 102.576 9.13417 104.301 8.67725 106.016Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M6.49758 87.6677C6.46204 87.7151 6.42649 87.7607 6.39096 87.8081C5.64764 88.7921 5.01645 89.8557 4.50906 90.9794C4.48198 91.0352 4.4566 91.091 4.4346 91.1485C3.73937 92.7175 3.29289 94.3852 3.11117 96.0914C3.10921 96.1032 3.10808 96.115 3.10779 96.1269C3.06548 96.5259 3.04009 96.9282 3.02994 97.3305C2.99231 98.0847 3.07398 98.84 3.27195 99.5688C3.45246 100.164 3.76876 100.709 4.19597 101.161C4.23828 101.207 4.28059 101.252 4.32629 101.296C4.36013 101.332 4.39736 101.367 4.4329 101.401C4.77476 101.727 5.14877 102.03 5.47032 102.38C5.6177 102.537 5.75079 102.707 5.86802 102.887C6.05511 103.19 6.17597 103.528 6.22264 103.881C6.26931 104.234 6.24073 104.592 6.1388 104.933C5.90864 105.788 5.38231 106.51 4.84415 107.198C4.57506 107.543 4.29751 107.891 4.0572 108.26C3.9692 108.391 3.88797 108.525 3.81351 108.662H3.60027C3.67135 108.525 3.7492 108.391 3.83382 108.26C4.45153 107.287 5.32986 106.452 5.7851 105.382C6.09311 104.66 6.18619 103.842 5.79695 103.134C5.68287 102.928 5.54484 102.736 5.38571 102.563C5.07601 102.213 4.70538 101.913 4.3686 101.597C4.28399 101.518 4.20106 101.437 4.12152 101.352C3.69982 100.924 3.37441 100.411 3.16703 99.8477C2.93218 99.1318 2.82115 98.3812 2.83871 97.6281C2.83871 97.148 2.8641 96.6645 2.91149 96.1844C2.91656 96.1235 2.92333 96.061 2.9301 95.9984C3.2762 92.9773 4.4256 90.1038 6.25896 87.6762C6.29281 87.6289 6.32835 87.5832 6.36389 87.5376C6.43665 87.4429 6.57035 87.5748 6.49758 87.6677Z"
                  fill="white"
                />
                <path
                  d="M2.99802 96.2005C2.41184 96.1022 1.87974 95.7989 1.49675 95.3449C1.11376 94.8908 0.904826 94.3156 0.907247 93.7218C0.907571 93.6976 0.91729 93.6745 0.934357 93.6573C0.951423 93.6401 0.974506 93.6302 0.998739 93.6297C1.02297 93.6291 1.04647 93.638 1.06427 93.6545C1.08207 93.6709 1.09279 93.6936 1.09418 93.7178C1.0909 94.2706 1.28558 94.8065 1.64308 95.2286C2.00058 95.6506 2.49736 95.9312 3.04374 96.0195C3.16246 96.0388 3.11605 96.2198 2.99802 96.2005Z"
                  fill="white"
                />
                <path
                  d="M4.14884 101.195C5.14473 100.5 5.84165 99.4552 6.10066 98.2694C6.12622 98.1519 6.30744 98.1977 6.28191 98.3151C6.01008 99.5486 5.28261 100.634 4.24467 101.356C4.14569 101.424 4.0504 101.264 4.14884 101.195Z"
                  fill="white"
                />
                <path
                  d="M4.45263 90.9596C4.6715 91.0448 4.90814 91.0745 5.1413 91.0458C5.37447 91.0171 5.59685 90.931 5.78848 90.7953C5.88654 90.7254 5.98173 90.8862 5.88431 90.9556C5.67189 91.1046 5.42617 91.1994 5.16863 91.2316C4.91109 91.2639 4.64957 91.2326 4.4069 91.1406C4.3833 91.1339 4.36316 91.1184 4.35057 91.0973C4.33799 91.0763 4.3339 91.0512 4.33914 91.0272C4.34532 91.0033 4.3607 90.9828 4.38195 90.9701C4.4032 90.9574 4.4286 90.9536 4.45263 90.9596Z"
                  fill="white"
                />
                <path
                  d="M19.1937 94.2049C19.1344 94.2505 19.0735 94.2962 19.0143 94.3435C18.2108 94.9577 17.4586 95.6362 16.7651 96.3721C16.711 96.4279 16.6568 96.4854 16.6044 96.5428L16.6027 96.5445C14.9584 98.3275 13.6582 100.399 12.7678 102.654L12.7627 102.669C12.761 102.674 12.7593 102.678 12.7576 102.683C12.4047 103.585 12.1201 104.513 11.9064 105.458C11.6999 106.381 11.5307 107.37 11.2074 108.26C11.1584 108.397 11.1042 108.532 11.0467 108.662H3.52246C3.54785 108.528 3.57492 108.393 3.602 108.26C3.98475 106.301 4.58296 104.39 5.38574 102.563C5.41282 102.502 5.4399 102.441 5.47036 102.38C5.88479 101.477 6.3721 100.609 6.92748 99.7852L6.9276 99.7845L6.92796 99.784L6.92852 99.7836L6.92917 99.7835C7.23413 99.3338 7.56174 98.9 7.91074 98.4835C8.81584 97.4078 9.88498 96.4814 11.0788 95.7382C11.0856 95.7331 11.0941 95.7297 11.1008 95.7246C13.4769 94.2522 16.293 93.5507 18.9855 94.1576H18.9872C19.0566 94.1728 19.1243 94.188 19.1937 94.2049Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M19.1852 94.2946C19.1276 94.3098 19.0701 94.3267 19.0143 94.3436C17.8282 94.6821 16.6834 95.151 15.6008 95.7416C15.5466 95.7704 15.4925 95.8008 15.4383 95.8312C13.9422 96.6653 12.5841 97.7253 11.4122 98.9738C11.4113 98.9747 11.4107 98.9759 11.4105 98.9772C11.397 98.9907 11.3817 99.0059 11.3699 99.0195C11.0991 99.3102 10.8385 99.6128 10.5914 99.9239C10.1081 100.504 9.71865 101.156 9.43722 101.856C9.22217 102.44 9.14627 103.065 9.21552 103.684C9.2206 103.746 9.22906 103.807 9.23752 103.869C9.24429 103.918 9.25106 103.968 9.25952 104.018C9.37122 104.696 9.56415 105.376 9.50999 106.067C9.47704 106.421 9.36937 106.765 9.19407 107.074C9.01876 107.384 8.7798 107.653 8.49289 107.864C8.29785 108.015 8.08938 108.147 7.8701 108.26C7.55658 108.421 7.22991 108.556 6.89361 108.662H6.22852C6.31821 108.637 6.40621 108.611 6.49591 108.586C6.8142 108.496 7.12673 108.387 7.43178 108.26C7.60605 108.187 7.77561 108.103 7.93949 108.009C8.61981 107.619 9.18676 107.021 9.30522 106.221C9.40845 105.521 9.20875 104.821 9.09029 104.137C9.06998 104.018 9.05137 103.902 9.04121 103.785C8.96041 103.192 9.00949 102.589 9.18506 102.017C9.42856 101.303 9.7926 100.636 10.2614 100.046C10.5514 99.6609 10.8609 99.2913 11.1888 98.9383C11.2294 98.8927 11.2717 98.847 11.3157 98.8014C12.5123 97.544 13.8939 96.4763 15.4129 95.6351H15.4146C16.5441 95.0071 17.7423 94.5113 18.9855 94.1576H18.9872C19.043 94.1407 19.1006 94.1238 19.1564 94.1086C19.2732 94.0782 19.3003 94.2624 19.1852 94.2946Z"
                  fill="white"
                />
                <path
                  d="M11.2491 99.0024C10.8403 98.5713 10.5983 98.0092 10.5662 97.4163C10.534 96.8234 10.7139 96.2385 11.0737 95.7658C11.1469 95.6705 11.2987 95.7795 11.2255 95.875C10.8896 96.3145 10.7221 96.8594 10.7531 97.4114C10.7842 97.9634 11.0117 98.4862 11.3947 98.8853C11.4779 98.9721 11.3317 99.0887 11.2491 99.0024Z"
                  fill="white"
                />
                <path
                  d="M9.15645 103.682C10.3707 103.726 11.5569 103.311 12.4784 102.52C12.5696 102.442 12.6867 102.587 12.5956 102.666C11.6351 103.487 10.3998 103.916 9.13633 103.868C9.0159 103.863 9.03667 103.678 9.15645 103.682Z"
                  fill="white"
                />
                <path
                  d="M15.5694 95.6928C15.6927 95.8926 15.8638 96.0585 16.0673 96.1759C16.2707 96.2932 16.5002 96.3582 16.735 96.3651C16.8554 96.3682 16.8345 96.5539 16.7149 96.5507C16.4555 96.5419 16.2022 96.4698 15.9771 96.3407C15.752 96.2115 15.562 96.0293 15.4237 95.8099C15.409 95.7903 15.4022 95.7658 15.4049 95.7415C15.4075 95.7171 15.4193 95.6946 15.438 95.6786C15.4573 95.6632 15.482 95.6561 15.5066 95.6587C15.5312 95.6614 15.5538 95.6737 15.5694 95.6928Z"
                  fill="white"
                />
                <path
                  d="M77.236 29.2673C77.0623 29.4955 76.8406 29.6829 76.5866 29.8163C76.3326 29.9497 76.0523 30.0258 75.7657 30.0393C75.479 30.0528 75.1929 30.0034 74.9274 29.8944C74.6619 29.7855 74.4236 29.6198 74.2292 29.4089L67.832 32.1881L68.4552 28.6209L74.4345 26.5286C74.8108 26.2259 75.2852 26.0715 75.7678 26.0948C76.2504 26.1181 76.7077 26.3174 77.053 26.655C77.3983 26.9926 77.6077 27.445 77.6414 27.9265C77.6751 28.4079 77.5309 28.885 77.236 29.2673Z"
                  fill="#FFB8B8"
                />
                <path
                  d="M72.967 30.3811L58.1086 36.9923L58.0767 36.9809L41.1418 30.9528C39.8804 30.2943 38.9187 29.179 38.4536 27.8353C37.9886 26.4916 38.0553 25.0212 38.6402 23.725C38.9644 23.0089 39.4368 22.3695 40.0262 21.8489C40.6157 21.3282 41.3088 20.9382 42.06 20.7045C42.8112 20.4707 43.6035 20.3986 44.3847 20.4927C45.1658 20.5869 45.9181 20.8452 46.5921 21.2507L59.1948 28.8341L71.4155 27.252L72.967 30.3811Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M146 108.799C146 108.825 145.995 108.851 145.985 108.876C145.975 108.9 145.96 108.923 145.941 108.941C145.923 108.96 145.9 108.975 145.876 108.985C145.852 108.995 145.825 109 145.799 109H0.207247C0.153835 109 0.102613 108.979 0.0648449 108.941C0.0270769 108.903 0.00585938 108.852 0.00585938 108.799C0.00585938 108.745 0.0270769 108.694 0.0648449 108.657C0.102613 108.619 0.153835 108.598 0.207247 108.598H145.799C145.825 108.598 145.852 108.603 145.876 108.613C145.9 108.623 145.923 108.638 145.941 108.656C145.96 108.675 145.975 108.697 145.985 108.722C145.995 108.746 146 108.772 146 108.799Z"
                  fill="#CCCCCC"
                />
                <path
                  d="M53.0287 50.173L38.0504 47.7798C37.6575 47.6541 37.2966 47.445 36.9923 47.1668C36.688 46.8887 36.4476 46.548 36.2875 46.1682C36.1274 45.7885 36.0515 45.3786 36.065 44.9668C36.0785 44.555 36.181 44.151 36.3655 43.7825L37.0448 42.4253L36.7809 28.1666C36.7491 26.3119 37.2069 24.4816 38.1081 22.8597C39.0094 21.2378 40.3224 19.8816 41.9149 18.9275L43.3756 18.0551L44.1138 16.1826L50.9205 16.3485L50.9403 18.8043L53.3779 22.687L53.3778 22.7031L53.2249 43.8365L52.5531 46.0174L53.5689 48.5541L53.0287 50.173Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M48.1406 13.8542C51.5563 13.8542 54.3252 11.0884 54.3252 7.6765C54.3252 4.26464 51.5563 1.49878 48.1406 1.49878C44.725 1.49878 41.9561 4.26464 41.9561 7.6765C41.9561 11.0884 44.725 13.8542 48.1406 13.8542Z"
                  fill="#FFB8B8"
                />
                <path
                  d="M41.7003 9.02313C41.5158 8.24688 41.6005 7.15456 41.7048 6.37296C41.9781 4.32387 43.1173 2.37586 44.848 1.22198C45.1762 0.972996 45.5662 0.818196 45.9759 0.774259C46.3854 0.757185 46.8292 0.976131 46.9549 1.36576C47.058 1.1086 47.2128 0.875294 47.4098 0.680229C47.6067 0.485164 47.8416 0.332484 48.0999 0.231612C48.6187 0.0330406 49.1768 -0.0420544 49.7297 0.0122796C50.8015 0.10053 51.8294 0.476987 52.7043 1.1017C53.5792 1.72641 54.2685 2.57607 54.699 3.56052C54.8642 3.9525 55.3256 2.5046 55.5349 2.87498C55.7272 3.25996 56.0628 3.55445 56.4697 3.69544C56.8798 3.80975 57.0464 5.49755 57.2078 5.10399C57.333 5.39484 57.3825 5.71262 57.3517 6.02773C57.3208 6.34284 57.2107 6.64503 57.0314 6.90615C56.8522 7.16728 56.6097 7.37885 56.3266 7.52116C56.0434 7.66347 55.7288 7.73189 55.4121 7.72005C54.9947 7.70445 54.5956 7.55109 54.1831 7.48508C52.7082 7.24901 51.1436 8.31733 50.8283 9.77578C50.7154 9.35401 50.5351 8.95322 50.2943 8.58889C50.172 8.40768 50.0082 8.25825 49.8165 8.15303C49.6248 8.04781 49.4107 7.98983 49.192 7.98392C48.7724 7.99961 48.4035 8.27366 48.1313 8.593C47.859 8.91234 47.6545 9.28491 47.3802 9.60253C46.5754 10.5345 45.4869 12.7079 44.34 12.5494C43.4349 12.4243 41.9522 10.083 41.7003 9.02313Z"
                  fill="#36454F"
                />
                <path
                  d="M85.5254 36.2677C85.5687 35.9861 85.5108 35.6983 85.3618 35.4552C85.2129 35.2122 84.9827 35.0297 84.7119 34.9401L68.0569 29.3992C67.8972 29.3458 67.7281 29.3264 67.5605 29.3423L63.386 29.7366L60.9907 29.9635L56.5705 30.3808C56.3036 30.4063 56.0527 30.5196 55.8572 30.7028C55.6617 30.8861 55.5326 31.1289 55.4901 31.3934L53.2459 45.3969C53.2034 45.6701 53.2554 45.9495 53.3934 46.1891C53.5314 46.4287 53.747 46.6142 54.0048 46.7148L70.8336 53.2036C70.9126 53.2343 70.9947 53.2563 71.0784 53.2693C71.2003 53.289 71.3244 53.29 71.4466 53.2721L82.3209 51.6621C82.5774 51.6251 82.8151 51.5064 82.9987 51.3237C83.1823 51.141 83.3021 50.904 83.3402 50.648L85.5254 36.2677Z"
                  fill="white"
                />
                <path
                  d="M54.3866 45.7281L71.2154 52.2166C71.24 52.2263 71.2666 52.2294 71.2928 52.2256L82.1657 50.6159C82.1979 50.6115 82.2278 50.5967 82.2509 50.5738C82.2739 50.5509 82.2889 50.521 82.2934 50.4889L84.4799 36.1085C84.4849 36.0733 84.4775 36.0376 84.459 36.0073C84.4405 35.9771 84.412 35.9542 84.3785 35.9425L84.2665 35.9047L67.7224 30.4029C67.7149 30.4002 67.7071 30.3983 67.6992 30.3972C67.6863 30.3949 67.6732 30.3942 67.6602 30.3952L57.4783 31.3577L56.6703 31.4334C56.6372 31.4369 56.6061 31.4512 56.5818 31.474C56.5575 31.4968 56.5414 31.527 56.5358 31.5598L54.2919 45.5628C54.2865 45.597 54.2929 45.6321 54.3101 45.6621C54.3273 45.6922 54.3543 45.7155 54.3866 45.7281Z"
                  fill="#666A40"
                />
                <path
                  d="M57.4785 31.3577L67.6604 30.3952C67.6735 30.3942 67.6865 30.3949 67.6994 30.3972C67.7073 30.3983 67.7151 30.4002 67.7226 30.4029L84.2667 35.9047L79.9027 36.3661L78.2695 36.538L73.6222 37.0284C73.6086 37.0291 73.595 37.029 73.5814 37.028C73.5732 37.0255 73.5649 37.023 73.555 37.0203L57.4785 31.3577Z"
                  fill="#36454F"
                />
                <path
                  d="M54.0044 46.7158L70.8332 53.2042C70.9124 53.2347 70.9947 53.2563 71.0787 53.2684C71.2006 53.2886 71.3248 53.2901 71.4472 53.273L82.32 51.6633C82.5766 51.626 82.8144 51.5071 82.9981 51.3244C83.1819 51.1416 83.3018 50.9045 83.3402 50.6484L85.525 36.2678C85.5573 36.0558 85.5329 35.839 85.4542 35.6395C85.4432 35.613 85.4321 35.5865 85.4192 35.5615C85.3326 35.3793 85.2007 35.2223 85.0361 35.1054C84.937 35.0344 84.8277 34.9787 84.7119 34.9403L84.6922 34.9334L68.0577 29.3991C67.9898 29.3775 67.9204 29.3606 67.85 29.3487C67.7539 29.3351 67.6564 29.3333 67.5598 29.3431L63.386 29.7361L60.9909 29.9643L56.5699 30.3813C56.3891 30.3981 56.2144 30.4558 56.0591 30.5499C56.0471 30.5557 56.0356 30.5626 56.0248 30.5704C56.0171 30.5754 56.0098 30.581 56.0028 30.587C55.8672 30.6795 55.7518 30.7985 55.6638 30.937C55.5757 31.0754 55.5168 31.2303 55.4906 31.3923L53.2449 45.3968C53.2024 45.6702 53.2545 45.9498 53.3926 46.1896C53.5307 46.4294 53.7465 46.615 54.0044 46.7158ZM55.7885 31.4411C55.8158 31.2732 55.8903 31.1166 56.0034 30.9895C56.0377 30.9486 56.076 30.9113 56.1178 30.8782C56.2319 30.7859 56.367 30.723 56.511 30.6949C56.5395 30.6891 56.5682 30.685 56.5972 30.6828L64.041 29.9803L67.5889 29.6431C67.613 29.6403 67.6351 29.6407 67.6573 29.6394C67.711 29.6383 67.7647 29.6423 67.8177 29.6513C67.8664 29.6576 67.9143 29.669 67.9606 29.6853L84.6148 35.2265C84.628 35.2312 84.6394 35.2357 84.6526 35.2403C84.804 35.2966 84.9371 35.3933 85.0373 35.5199C85.1165 35.6168 85.1743 35.7293 85.207 35.8501C85.2397 35.9708 85.2465 36.0971 85.2269 36.2207L83.0403 50.6028C83.0116 50.7946 82.922 50.9721 82.7846 51.1091C82.6472 51.2461 82.4693 51.3354 82.2773 51.3637L71.4027 52.9733C71.2472 52.9956 71.0885 52.9775 70.9419 52.9208L54.113 46.434C53.9201 46.3581 53.7587 46.219 53.6554 46.0394C53.5521 45.8598 53.513 45.6505 53.5445 45.4458L55.7885 31.4411Z"
                  fill="#36454F"
                />
                <path
                  d="M55.9671 30.6632C55.976 30.6377 55.9917 30.6152 56.0125 30.5979C56.0193 30.5924 56.0266 30.5874 56.0343 30.583C56.0413 30.5803 56.0486 30.5759 56.0556 30.5732C56.0894 30.5596 56.127 30.559 56.1612 30.5714L56.5115 30.6948L73.6239 36.7246L77.6288 36.302L79.87 36.0658L84.6136 35.5642L85.0377 35.5198L85.1202 35.5107C85.1558 35.5074 85.1913 35.5167 85.2207 35.5369C85.2502 35.557 85.2716 35.5869 85.2814 35.6212C85.2836 35.629 85.2851 35.6372 85.2858 35.6453C85.2893 35.6779 85.2821 35.7107 85.2653 35.7388C85.2485 35.7669 85.2231 35.7888 85.1927 35.8012C85.1793 35.8067 85.1652 35.8105 85.1508 35.8125L84.5738 35.8729L79.9025 36.3661L78.2693 36.538L73.622 37.0284C73.6084 37.0291 73.5948 37.029 73.5813 37.0279C73.573 37.0255 73.5647 37.023 73.5548 37.0203L56.1183 30.878L56.0591 30.8571C56.0455 30.8526 56.0329 30.8457 56.0219 30.8366C55.9955 30.8172 55.976 30.79 55.9661 30.7588C55.9563 30.7277 55.9566 30.6942 55.9671 30.6632Z"
                  fill="#36454F"
                />
                <path
                  d="M71.1244 53.3125C71.0851 53.3062 71.0498 53.2846 71.0262 53.2524C71.0027 53.2203 70.9928 53.1801 70.9988 53.1407L73.4555 36.8556C73.4615 36.816 73.483 36.7804 73.5153 36.7566C73.5476 36.7328 73.588 36.7228 73.6276 36.7287L73.629 36.729C73.6684 36.7352 73.7037 36.7568 73.7272 36.789C73.7507 36.8211 73.7606 36.8613 73.7547 36.9007L71.2979 53.1858C71.2919 53.2254 71.2704 53.261 71.2381 53.2848C71.2059 53.3086 71.1654 53.3186 71.1258 53.3127L71.1244 53.3125Z"
                  fill="#36454F"
                />
                <path
                  d="M77.3517 52.2792L75.7178 52.4514L77.629 36.3014C77.629 36.3014 79.8112 35.9068 79.8696 36.065C79.9055 36.1595 77.3673 52.1756 77.3517 52.2792Z"
                  fill="#36454F"
                />
                <path
                  d="M56.0596 30.8569L56.0156 38.2219L69.0681 43.1899L73.7554 36.9006L56.0596 30.8569Z"
                  fill="#36454F"
                />
                <path
                  d="M85.038 35.5201C84.9288 35.3831 84.7819 35.281 84.6154 35.2265L67.9616 29.6855C67.9149 29.6698 67.8669 29.6581 67.8181 29.6505C67.765 29.6414 67.7111 29.6374 67.6572 29.6385L67.6842 29.4699L67.851 29.3494L71.8057 26.4893L85.0129 30.5324L85.0358 35.1054L85.038 35.5201Z"
                  fill="#36454F"
                />
                <path
                  d="M56.7241 42.6801C56.7429 42.6819 56.7614 42.6858 56.7793 42.6917L61.8891 44.3681C61.9531 44.389 62.0061 44.4346 62.0365 44.4946C62.0669 44.5546 62.0721 44.6242 62.0512 44.6881C62.0302 44.752 61.9846 44.805 61.9245 44.8353C61.8644 44.8657 61.7947 44.871 61.7308 44.85L56.6209 43.1736C56.5616 43.1541 56.5116 43.1135 56.4803 43.0594C56.4491 43.0054 56.4389 42.9418 56.4516 42.8808C56.4644 42.8197 56.4993 42.7655 56.5495 42.7285C56.5998 42.6914 56.6619 42.6742 56.7241 42.6801Z"
                  fill="white"
                />
                <path
                  d="M77.3414 73.576C78.463 73.576 79.3722 72.6677 79.3722 71.5474C79.3722 70.427 78.463 69.5188 77.3414 69.5188C76.2198 69.5188 75.3105 70.427 75.3105 71.5474C75.3105 72.6677 76.2198 73.576 77.3414 73.576Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M25.5552 81.0139C26.6768 81.0139 27.5861 80.1057 27.5861 78.9854C27.5861 77.865 26.6768 76.9568 25.5552 76.9568C24.4336 76.9568 23.5244 77.865 23.5244 78.9854C23.5244 80.1057 24.4336 81.0139 25.5552 81.0139Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M67.8638 13.9019C68.9854 13.9019 69.8947 12.9937 69.8947 11.8733C69.8947 10.753 68.9854 9.84473 67.8638 9.84473C66.7422 9.84473 65.833 10.753 65.833 11.8733C65.833 12.9937 66.7422 13.9019 67.8638 13.9019Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M71.1962 45.6636C70.933 45.7779 70.6479 45.8331 70.361 45.8253C70.0742 45.8176 69.7925 45.7471 69.5358 45.6189C69.2791 45.4906 69.0538 45.3077 68.8755 45.0831C68.6973 44.8584 68.5705 44.5974 68.5042 44.3185L61.5635 43.6181L63.8533 40.8108L70.0932 41.9063C70.5696 41.8261 71.059 41.9231 71.4686 42.1792C71.8781 42.4352 72.1795 42.8325 72.3154 43.2956C72.4514 43.7587 72.4126 44.2556 72.2063 44.692C72 45.1285 71.6406 45.4742 71.1962 45.6636Z"
                  fill="#FFB8B8"
                />
                <path
                  d="M66.9272 44.5499L50.7299 43.0592L50.7077 43.0337L38.8865 29.5037C38.1085 28.3131 37.8156 26.8705 38.0677 25.4713C38.3198 24.072 39.0979 22.822 40.2426 21.9771C40.876 21.5107 41.601 21.1837 42.37 21.0175C43.1391 20.8513 43.9346 20.8496 44.7043 21.0127C45.474 21.1757 46.2004 21.4997 46.8357 21.9634C47.471 22.4271 48.0008 23.0199 48.3902 23.7029L55.6711 36.4735L67.1056 41.0627L66.9272 44.5499Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M107.581 18.5304C107.179 15.5833 106.674 12.4191 104.593 10.2916C103.933 9.6165 103.143 9.08201 102.27 8.72029C101.397 8.35857 100.46 8.17713 99.5156 8.1869C98.5708 8.19667 97.6378 8.39744 96.7728 8.77713C95.9078 9.15681 95.1287 9.70753 94.4826 10.3961C92.9678 12.0103 92.2988 14.2769 92.2376 16.4886C92.1764 18.7002 92.6695 20.8873 93.2165 23.0313C96.1114 23.0891 98.985 22.5241 101.642 21.3748C102.306 21.0867 102.96 20.761 103.662 20.5825C104.364 20.4041 104.865 20.8532 105.517 21.1663L105.876 20.4808C106.169 21.0262 107.078 20.8645 107.407 20.3401C107.737 19.8156 107.665 19.144 107.581 18.5304Z"
                  fill="#36454F"
                />
                <path
                  d="M96.1058 34.9805L84.2803 35.2331C84.0987 34.9195 83.8298 34.6654 83.5062 34.5017C83.1827 34.338 82.8185 34.2718 82.458 34.3111C82.0975 34.3504 81.7562 34.4935 81.4756 34.723C81.195 34.9525 80.9872 35.2585 80.8776 35.6038C80.7679 35.9491 80.7611 36.3188 80.858 36.6679C80.9549 37.017 81.1512 37.3305 81.4232 37.5701C81.6951 37.8098 82.0309 37.9654 82.3898 38.0178C82.7486 38.0703 83.115 38.0175 83.4443 37.8658C85.0127 37.9549 97.283 39.6945 98.015 38.5734C98.758 37.4355 102.724 33.4198 102.724 33.4198L99.6755 29.0847L96.1058 34.9805Z"
                  fill="#9E616A"
                />
                <path
                  d="M98.9877 21.4464C102.056 21.4464 104.544 18.9617 104.544 15.8966C104.544 12.8314 102.056 10.3467 98.9877 10.3467C95.9192 10.3467 93.4316 12.8314 93.4316 15.8966C93.4316 18.9617 95.9192 21.4464 98.9877 21.4464Z"
                  fill="#9E616A"
                />
                <path
                  d="M107.886 26.575C107.232 25.2733 106.913 23.6653 105.518 23.2433C104.575 22.9584 100.763 23.3854 99.9035 23.8664C98.4746 24.6665 99.7695 26.1659 98.9265 27.5689C98.01 29.0942 95.5194 32.1552 94.603 33.6804C93.973 34.729 96.0952 37.8068 95.7545 38.9815C95.4139 40.1561 95.3975 41.4745 95.9778 42.5513C96.4971 43.5151 95.7457 44.3677 96.2681 45.3299C96.8112 46.3306 97.4778 50.0722 96.9723 51.0924L96.6338 52.1067C99.9926 52.3039 102.817 48.2957 106.155 47.8734C106.984 47.7686 107.845 47.6316 108.528 47.1509C109.534 46.4428 109.932 45.1579 110.181 43.9537C111.377 38.0673 110.57 31.9503 107.886 26.575Z"
                  fill="#36454F"
                />
                <path
                  d="M112.923 40.3189C112.455 39.0434 111.608 33.4663 111.608 33.4663L106.307 33.311L108.899 39.6963L101.826 49.5861C101.826 49.5861 101.839 49.6079 101.863 49.6466C101.491 49.587 101.11 49.6409 100.769 49.8013C100.429 49.9617 100.144 50.221 99.9537 50.5454C99.7631 50.8698 99.6749 51.2442 99.7007 51.6195C99.7265 51.9948 99.8652 52.3535 100.098 52.6488C100.332 52.9441 100.649 53.1622 101.008 53.2745C101.368 53.3868 101.753 53.3881 102.113 53.2782C102.473 53.1683 102.792 52.9524 103.027 52.6587C103.262 52.365 103.403 52.0072 103.432 51.6321C103.458 51.647 103.488 51.6524 103.518 51.6473C103.547 51.6422 103.574 51.627 103.594 51.6043C104.381 50.6178 113.391 41.5945 112.923 40.3189Z"
                  fill="#9E616A"
                />
                <path
                  d="M113.461 36.7967C112.975 34.7453 112.485 32.6828 111.664 30.7404C110.844 28.798 109.669 26.9634 108.018 25.6512C107.49 25.2316 106.899 24.8613 106.237 24.7309C105.575 24.6004 104.829 24.7464 104.364 25.2349C103.62 26.0165 103.844 27.2711 104.117 28.3146C104.922 31.3913 105.726 34.4681 106.531 37.5448C108.854 37.2988 111.178 37.0528 113.501 36.8068L113.461 36.7967Z"
                  fill="#36454F"
                />
                <path
                  d="M105.796 16.6663C105.591 15.3237 105.377 13.9699 104.897 12.7057C104.416 11.4415 103.641 10.2575 102.53 9.54132C100.77 8.4075 98.4913 8.62618 96.5182 9.27838C94.9923 9.78277 93.5028 10.5494 92.4268 11.7819C91.3507 13.0144 90.7464 14.773 91.1467 16.3845C93.2513 15.8878 95.356 15.3911 97.4607 14.8944L97.2303 15.057C97.9213 15.4012 98.5248 15.8981 98.9949 16.5101C99.465 17.1221 99.7893 17.833 99.9432 18.5889C100.094 19.3539 100.072 20.1428 99.88 20.8985C99.6878 21.6542 99.3299 22.3578 98.8321 22.9584C100.268 22.4497 101.703 21.9411 103.138 21.4325C104.024 21.1185 104.973 20.7568 105.498 19.9515C106.107 19.0179 105.965 17.7805 105.796 16.6663Z"
                  fill="#36454F"
                />
              </g>
              <defs>
                <clipPath id="clip0_3843_23070">
                  <rect width="146" height="109" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <p className="text-center text-[#36454F] text-[16px] mt-5">
              No Preview to Display 👀
            </p>
            <p className="text-center text-[#A9A9A9] text-[14px]">
              Select an employee to display their whole attendance information
            </p>
          </div>
        ) : (
          <div className="bg-white border border-[#e4e4e4] rounded-[15px] w-full p-5 mt-10">
            {/* Name card and attendance details */}


            {selectedStatus.map((ss) => (
              <div className="flex flex-row justify-between items-center">
                {/* Profile DP, name and position */}

                <div className="flex flex-row justify-start items-center gap-3">
                  <div
                    className={`rounded-full h-[70px] w-[70px] ${bgColor} text-white text-[24px] font-medium flex justify-center items-center`}
                  >
                    {(ss?.emp_pic) ? <img className={`box-border w-[70px] h-[70px] rounded-full`} src={ss?.emp_pic} /> : ss?.f_name.charAt(0) + ss?.s_name.charAt(0)}
                    {/* {ss.f_name?.charAt(0) + ss.s_name?.charAt(0)} */}
                  </div>

                  <div>
                    <p className="text-[#363636] text-[18px] font-bold">
                      {ss.f_name + " " + ss.s_name}
                    </p>
                    <p className="text-[15px] text-[#8b8b8b]">
                      {ss.position_name}
                    </p>
                    <p className="text-[12px] text-[#8b8b8b]">
                      {ss.start && ss.end
                        ? moment(ss.start, "HH:mm:ss a").format("hh:mm a") +
                          " - " +
                          moment(ss.end, "HH:mm:ss a").format("hh:mm a")
                        : "No Shift Registered"}
                    </p>
                  </div>

                </div>

                <div className="flex flex-row justify-end items-center gap-5">
                  <div>
                    <p className="text-[24px] font-bold text-[#363636] text-center leading-none">
                      {ss.early_start}
                    </p>
                    <p className="text-[12px] text-[#898989] text-center">
                      Early Start
                    </p>
                  </div>

                  <div className=" border-r-2 border-[#e4e4e4] h-8" />

                  <div>
                    <p className="text-[24px] font-bold text-[#363636] text-center leading-none">
                      {ss.late_start}
                    </p>
                    <p className="text-[12px] text-[#898989] text-center">
                      Late Start
                    </p>
                  </div>

                  <div className=" border-r-2 border-[#e4e4e4] h-8" />

                  <div>
                    <p className="text-[24px] font-bold text-[#363636] text-center leading-none">
                      {ss.overtime}
                    </p>
                    <p className="text-[12px] text-[#898989] text-center">
                      Overtime
                    </p>
                  </div>

                  <div className=" border-r-2 border-[#e4e4e4] h-8" />

                  <div>
                    <p className="text-[24px] font-bold text-[#363636] text-center leading-none">
                      {ss.undertime}
                    </p>
                    <p className="text-[12px] text-[#898989] text-center">
                      Undertime
                    </p>
                  </div>

                  <div className=" border-r-2 border-[#e4e4e4] h-8" />

                  <div>
                    <p className="text-[24px] font-bold text-[#363636] text-center leading-none">
                      {ss.completed}
                    </p>
                    <p className="text-[12px] text-[#898989] text-center">
                      Completed
                    </p>
                  </div>

                  <div className=" border-r-2 border-[#e4e4e4] h-8" />

                  <div>
                    <p className="text-[24px] font-bold text-[#363636] text-center leading-none">
                      {ss.data_incomplete}
                    </p>
                    <p className="text-[12px] text-[#898989] text-center">
                      Data Incomplete
                    </p>
                  </div>

                  <div className=" border-r-2 border-[#e4e4e4] h-8" />

                  <div
                    className="w-[50px] font-bold p-2 flex flex-col justify-center items-center bg-white text-[14px] rounded-[15px] border border-[#e4e4e4]"
                    onClick={() =>
                      document.getElementById("add_new_date_modal").showModal()
                    }
                  >
                    <span>+</span>
                  </div>
                </div>
              </div>
            ))
              }

            {(isViewLoading) ?
              <div className="w-full flex justify-center align-center">
                <span className="loading loading-spinner loading-lg"></span>
              </div>
            :

              <div className="overflow-x-auto mt-5">
                <DataTable
                  columns={columns}
                  data={selectedAttendance}
                  highlightOnHover
                  pagination
                  progressPending={loading}
                  paginationServer
                  paginationTotalRows={totalRows2}
                  onChangeRowsPerPage={handlePerRowsChange2}
                  onChangePage={handlePageChange2}
                  responsive
                  //conditionalRowStyles={(isChecked) && conditionalRowStyles}
                />
              </div>
            }

            {/* Time table and editable contents */}
          </div>
        )}

        <div className="bg-white rounded-[15px] border border-[#e4e4e4] mt-10">
          <p className="py-3 px-5 text-[18px] font-bold text-[#36454F] border-b border-[#e4e4e4]">
            Employee Attendance List
          </p>

          <div className="p-5">
            <div className={`w-full rounded-[8px] p-2 ${lightColor}`}>
              <div className="flex flex-row justify-start gap-2 max-w-[700px]">

                <input
                  type="text"
                  value={searchTerm}
                  className="outline-none border border-[#e4e4e4] px-2 py-1 rounded-[5px] text-[14px] text-[#363636] flex-1"
                  placeholder="Search Employee..."
                  onChange={(e) => setSearchTerm(e.target.value)}
                />

                <button 
                    className="bg-[#666A40] px-2 py-2 rounded-[8px] flex flex-row flex-nowrap justify-center items-center gap-1 h-full"
                    onClick={() => handleSearch()}
                    >
                    <span className="text-white text-[14px]">Search</span>
                </button>

                {(isSearch) &&
                  <button 
                      className="bg-[#666A40] px-2 py-2 rounded-[8px] flex flex-row flex-nowrap justify-center items-center gap-1 h-full"
                      onClick={() => {setAttendanceList(defaultData)
                                      setIsSearch(false)
                                      setSearchTerm("")}}
                      >
                      <span className="text-white text-[14px]">Reset</span>
                  </button>
                }

                <button
                  onClick={() => uploadBtnRef.current.showModal()}
                  className={`outline-none transition-all ease-in-out ${bgColor} ${hoverColor} px-4 text-white text-[14px] rounded-[5px]`}
                >
                  Upload Attendance
                </button>

                <div className={`flex items-center ${bgColor} ${hoverColor} rounded-[5px]`}>
                  <a 
                    href="../../files/Template - Kriya Attendance.csv" 
                    download="Template - kriya Attendance.csv"
                    className={`outline-none transition-all ease-in-out px-4 text-white text-[14px]`}
                  >
                    Download Template
                  </a>
                </div>

              </div>
            </div>
            

            <div className="overflow-x-auto mt-5">
              <DataTable
                columns={allAttendanceColumns}
                data={attendanceList}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                highlightOnHover
                responsive
              />
            </div>


          </div>
        </div>
      </div>

      {/* -------------------------------------------- Modals ------------------------------------------ */}

      <dialog className="modal outline-none p-5" ref={uploadBtnRef}>
        <div className="w-full max-h-[700px] flex flex-col bg-white rounded-[15px]">
          <div className="flex flex-row justify-between items-center p-5">
            <p className="text-[18px] font-bold text-[#363636]">
              Upload Attendance
            </p>

            <button onClick={() => uploadBtnRef.current.close()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="w-6 h-6 fill-[#A9A9A9]"
              >
                <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm4.207 12.793-1.414 1.414L12 13.414l-2.793 2.793-1.414-1.414L10.586 12 7.793 9.207l1.414-1.414L12 10.586l2.793-2.793 1.414 1.414L13.414 12l2.793 2.793z"></path>
              </svg>
            </button>
          </div>

          <div className="flex-1 overflow-auto p-5">
            <CSVReader
              onUploadAccepted={(results) => {
                const value = results.data;
                const filtered = value.filter((_, i) => i !== 0);
                setCol(value[0]);
                setVal(filtered);
              }}
              config={{ worker: true }}
            >
              {({ getRootProps, acceptedFile, getRemoveFileProps }) => (
                <>
                  <div {...getRootProps()}>
                    {acceptedFile ? (
                      <>
                        <table className="table">
                          <thead className={`${lightColor} ${textColor}`}>
                            {col.map((tableHeaders) => (
                              <td>{tableHeaders}</td>
                            ))}
                          </thead>

                          <tbody>
                            {val.map((info) => (
                              <tr>
                                {info.map((data) => (
                                  <td>{data}</td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <div className="box-border flex flex-row justify-end gap-2 mt-10">
                          <button
                            {...getRemoveFileProps()}
                            className="outline-none border border-[#363636] text-[#363636] text-[14px] px-3 py-2 rounded-[8px]"
                          >
                            <span>Cancel</span>
                          </button>

                          <button
                            onClick={() => 
                              handleSubmit()}
                             className={`outline-none transition-all ease-in-out text-white rounded-[8px] text-[14px] px-3 py-2 ${bgColor} ${hoverColor}`}
                          >
                            <span>Upload Data</span>
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="box-border w-full border-2 border-[#e4e4e4] border-dashed bg-white h-52 flex flex-col justify-center items-center rounded-[15px] cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="fill-[#a9a9a9] w-28 h-28"
                        >
                          <path d="M6 22h12a2 2 0 0 0 2-2V8l-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2zm7-18 5 5h-5V4zM8 14h3v-3h2v3h3v2h-3v3h-2v-3H8v-2z"></path>
                        </svg>
                        <p className="text-[16px] text-[#A9A9A9] select-none">
                          Click or drag and drop a file here
                        </p>
                      </div>
                    )}
                  </div>
                </>
              )}
            </CSVReader>
          </div>
        </div>
      </dialog>


      {/* <dialog className="modal outline-none p-5" ref={uploadBtnRef}>
        <div className="w-full max-h-[700px] flex flex-col bg-white rounded-[15px]">
          <div className="flex flex-row justify-between items-center p-5">
            <p className="text-[18px] font-bold text-[#363636]">
              Upload Attendance
            </p>

            <button onClick={() => uploadBtnRef.current.close()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="w-6 h-6 fill-[#A9A9A9]"
              >
                <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm4.207 12.793-1.414 1.414L12 13.414l-2.793 2.793-1.414-1.414L10.586 12 7.793 9.207l1.414-1.414L12 10.586l2.793-2.793 1.414 1.414L13.414 12l2.793 2.793z"></path>
              </svg>
            </button>
          </div>

          <div className="flex-1 overflow-auto p-5">
            <CSVReader
              onUploadAccepted={(results) => {
                const value = results.data;
                const filtered = value.filter((_, i) => i !== 0);
                setCol(value[0]);
                setVal(filtered);
              }}
              config={{ worker: true }}
            >
              {({ getRootProps, acceptedFile, getRemoveFileProps, ProgressBar, Remove }) => (
                <>
                  <div {...getRootProps()}>
                    {acceptedFile ? (
                      <>
                        <table className="table">
                          <thead className={`${lightColor} ${textColor}`}>
                            {col.map((tableHeaders) => (
                              <td>{tableHeaders}</td>
                            ))}
                          </thead>

                          <tbody>
                            {val.map((info) => (
                              <tr>
                                {info.map((data) => (
                                  <td>{data}</td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <div className="box-border flex flex-row justify-end gap-2 mt-10">
                          <button
                            {...getRemoveFileProps()}
                            className="outline-none border border-[#363636] text-[#363636] text-[14px] px-3 py-2 rounded-[8px]"
                          >
                            <span>Cancel</span>
                          </button>

                          <button
                            onClick={() => 
                              handleSubmit()}
                             className={`outline-none transition-all ease-in-out text-white rounded-[8px] text-[14px] px-3 py-2 ${bgColor} ${hoverColor}`}
                          >
                            <span>Upload Data</span>
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="box-border w-full border-2 border-[#e4e4e4] border-dashed bg-white h-52 flex flex-col justify-center items-center rounded-[15px] cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="fill-[#a9a9a9] w-28 h-28"
                        >
                          <path d="M6 22h12a2 2 0 0 0 2-2V8l-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2zm7-18 5 5h-5V4zM8 14h3v-3h2v3h3v2h-3v3h-2v-3H8v-2z"></path>
                        </svg>
                        <p className="text-[16px] text-[#A9A9A9] select-none">
                          Click or drag and drop a file here
                        </p>
                      </div>
                    )}
                  </div>
                </>
              )}
            </CSVReader>
          </div>
        </div>
      </dialog> */}

      {/* Modal - File A Dispute   */}
      <dialog id="add_new_date_modal" className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-xl text-center">Add New Date</h3>

          <form id="newDateForm" action="" method="dialog">
            <br />

            <div className="flex">
              <div className="flex-1 mx-1 w-full mb-10">
                <label>
                  <div className="label">
                    <h1 className="label-text">
                      Dispute Date <span className="text-red-500"> *</span>
                    </h1>
                  </div>

                  <div>
                    <DatePicker
                      //selected={startDate}
                      className="input input-bordered w-full max-w-xs mb-2"
                      placeholderText="Add Date"
                      isClearable
                      disabledKeyboardNavigation
                      maxDate={new Date()}
                      selected={newDate.date}
                      //filterDate={isExisting}
                      onChange={(date) =>
                        setNewDate({ ...newDate, date: date })
                      }

                    />
                  </div>
                </label>
              </div>
            </div>

            <div>
              <div className="flex">
                {/* Time In */}

                <div className="flex-1 mx-1">
                  <label>
                    <div className="label">
                      <h1 className="label-text">
                        Time In <span className="text-red-500"> *</span>
                      </h1>

                      <input
                        type="time"
                        className={`bg-white w-[120px] input input-bordered transition-all ease-in-out outline-none border border-[#e4e4e4] disabled:border-transparent px-1 rounded-[3px]`}
                        onChange={(e) =>
                          setNewDate({
                            ...newDate,
                            time_in: e.target.value,
                          })
                        }
                      />
                    </div>
                  </label>
                </div>

                {/* Time Out */}

                <div className="flex-1 mx-1">
                  <label>
                    <div className="label">
                      <h1 className="label-text">
                        Time Out <span className="text-red-500"> *</span>
                      </h1>

                      <input
                        type="time"
                        className={`bg-white w-[120px] input input-bordered transition-all ease-in-out outline-none border border-[#e4e4e4] disabled:border-transparent px-1 rounded-[3px]`}
                        onChange={(e) =>
                          setNewDate({
                            ...newDate,
                            time_out: e.target.value,
                          })
                        }
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div className="divider"></div>

            {/* Button Container */}
            <div className="flex justify-end mt-3">
              <button
                id="submit-button"
                type="submit"
                className="btn btn-primary mr-2"
                onClick={(e)=> handleAddNewDate(e)}
                // onClick={handlePTOpoints}
                // disabled={isDisabled}
              >
                Submit
              </button>

              {/* Cancel Button */}
              {/* If there is a button in form, it will close the modal */}
              <button
                className="btn"
                type="button"
                onClick={() =>
                  document.getElementById("add_new_date_modal").close()
                }
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </dialog>
    </>
  );
};

export default HRTimeOffAndAttendance;
